import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "/src/layouts/layout";
import Video from "/src/components/video";
import JumpstartYourCareer from "/src/components/jumpstart-your-career";

const SkilledCraft = ({ data: { hero, pageData } }) => {


return (

<Layout hero={hero}>
    <div className="container mx-auto mb-16">
        <div className="md:flex justify-between mb-16">

            <div className="flex-1 px-4 mr-4 sm:mr-8 mb-4 self-start order-first">
                <Img className="object-cover object-center w-full h-full block" fluid={pageData.promoImage.fluid} />
            </div>

            <div className="flex-1 px-4 mb-4">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Are you ready to make your mark on the world?</h2>
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoOneText.childMarkdownRemark.html }}
                />                    

            </div>

               
    </div>
</div>

<div style={{backgroundColor: "#f5f5f5"}} className="border-t border-b">
    <div className="container py-8 mx-auto mb-16">

        {pageData.promoTwoText &&
        <div className="md:flex items-center justify-between mb-16">
            <div className="flex-1 px-4 mb-4 order-first">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Students</h2>
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoTwoText.childMarkdownRemark.html }}
                />                    
                <div className="inline-block uppercase border-b-2 text-xs pb-1 border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <a href="#">Find an Opportunity</a>
                </div>
            </div>

            <div className="flex-1 px-4 text-right sm:mb-0 self-start">
                <Img className="object-cover object-center ml-auto sm:w-4/5 h-full block" fluid={pageData.promoTwoImage.fluid} />
            </div>
        </div>
        }

        {pageData.promoThreeText &&
        <div className="md:flex items-center justify-between mb-16">
            <div className="flex-1 px-4 mb-4">
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight tracking-tight font-aktiv mb-4">Recent Grads</h2>
                {pageData.promoThreeText && 
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoThreeText.childMarkdownRemark.html }}
                />                    
                }
                <div className="inline-block uppercase border-b-2 text-xs pb-1 border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <a href="#">Find an Opportunity</a>
                </div>
            </div>
            <div className="flex-1 px-4 sm:mb-0">
                <Img className="object-cover object-center ml-auto h-full block sm:w-4/5" fluid={pageData.promoThreeImage.fluid} />
            </div>
        </div>
        }

    </div>
</div>




    {/*
        <div className="px-4 pt-16 container mx-auto mb-16 sm:flex">
            <div className="sm:flex">
        
                <div className="sm:w-1/2 sm:px-4 mb-6 sm:mr-6 sm:mb-0">
                    <div className="mx-auto">
                        <Video url="https://firebasestorage.googleapis.com/v0/b/mane-5e387.appspot.com/o/manwhoarewevideo.mp4?alt=media&token=f4bf4c3f-7e0d-42e9-9083-519446abb07d" />
                    </div>
                </div>

                <div className="sm:w-1/2 mb-8 sm:px-4">
                    <p className="text-2xl mb-4 soft-gray">
                        "My internship at MAN helped me learn valuable skills, and understand how a world-class business operates. I also gained a job at MAN as a result."  
                    </p>

                    <p className="font-aktiv font-bold text-xl soft-gray">Andre Topchi</p>
                    <p className="font-aktivcd font-bold text-xs uppercase dark-blue">Former intern at MAN Enterprise</p>
                </div>

            </div>
        </div>
    */}

    <JumpstartYourCareer />


</Layout>

)};

export const query = graphql`
    query SkilledCraft {
      hero: contentfulCareersSubPage(slug: {eq: "skilled-craft"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
        heroText: heroSubtext
      }

      pageData:contentfulCareersSubPage (slug: {eq: "skilled-craft"}) {
        promoOneText {
           childMarkdownRemark {
                   html
             }
        }
        promoImage { 
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoImage {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoText {
          childMarkdownRemark {
            html
          }
       }  
       promoThreeText {
        childMarkdownRemark {
            html
        }
       }
       promoThreeImage {
        fluid  {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
       }
      }
    }
`;


export default SkilledCraft;
