import React, {useState, useRef} from 'react';

const Video = ({url}) => { 

    console.log(url);
    const vimeoId = url.replace(/\D/g, ''); //get id from vimeo link
    console.log(vimeoId);

    return (
            <div style={{padding:"56.25% 0 0 0",position:"relative"}}>
                <iframe src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&sidedock=0`} style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
    )

    /*
    const [clicked, setClicked] = useState(false);

    const videoRef = useRef(null);

    const handlePlayButton = () => {
        videoRef.current.play();
        setClicked(true);
    };

    return (
      <div className="video relative cursor-pointer">
          <video ref={videoRef} controls>
              <source src={url} type="video/mp4" />
          </video>
          {!clicked &&
          <div className="play-button-container center">
              <button className="play-button" onClick={() => handlePlayButton()} id="playVideoButton"></button>
              <p className="text-white text-lg play-button-duration whitespace-no-wrap">Duration: 5:39</p>
          </div>
          }
      </div>
    )
    */

}

export default Video;
