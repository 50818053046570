import React from "react"
import { Link, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

const JumpstartYourCareer = () =>  {

   const jumpstart = useStaticQuery(graphql`
        query jumpstartQuery {
            background: contentfulAsset(contentful_id: {eq: "GRYNKbwO3J3UFcL88Tf6o"}) {
                fluid  {
                    ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
         site {
            siteMetadata {
                careers {
                    application_url
                }
             }
       }

        }
    `)


    return (
    <div className="mb-16 relative overflow-hidden sm:py-20 bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${jumpstart.background.fluid.src})`}}>
        <div className="container mx-auto">
            <div className="flex sm:w-1/2 p-4 sm:p-8 py-8 sm:-ml-4" style={{backgroundColor:'rgba(10, 30, 49, 0.9)'}}>
                <div className="flex flex-col">
                    <div className="sm:w-4/5 mb-4">
                    <h2 className="text-3xl sm:text-5xl font-bold text-white leading-tight"><span className="text-blue-200 block">Make an Impact!</span></h2>
                    </div>
                    <div className="text-white leading-relaxed nested-html">
                        <p> When choosing MAN Enterprise, you are getting more than just an employer. It is an opportunity to grow and build lasting relationships where individuals, quality and values are what truly matters. You will get the opportunity to grow your expertise in different sectors of the construction industry.  </p>
                        <p>Our history, client base, the variety of work we do innovatively, and the investment we make in our people’s development will help you grow your career successfully.</p>
                    </div>

                    <div style={{width:'max-content'}} className="inline-block uppercase pt-6 pb-1 border-b-2 text-xs border-mane-yellow text-white font-bold font-aktivcd">
                       <Link target="_blank" to={jumpstart.site.siteMetadata.careers.application_url}>
                         Find An Opportunity
                       </Link>

                    </div>

                </div>
            </div>
        </div>
    </div>
    )
}

export default JumpstartYourCareer;
